// Packages
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

// Components
import Logo from "../Logo/logo"
import BrandName from "../BrandName/brandname"

// Styles
import css from "./brand.module.scss"

const Brand = ({ innerColor }) => {
  

  const [brandColor, setBrandColor] = useState(innerColor);

  useEffect(() => {
    const ua = window.navigator.userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod/i
    )

    if (ua) {
      setBrandColor("#ffffff")
      
    } else {
      setBrandColor(innerColor);
    }
    
  }, [innerColor])

  
  return (
    <div className={css.brand}>
      <Link className={css.brand__link} to="/">
        <Logo
          size={64}
          LineColor1={brandColor}
          LineColor1={brandColor}
          LineColor2={brandColor}
          LineColorOuter={brandColor}
        />
        <BrandName
          imageWidth={200}
          className={css.brand__name}
          color={brandColor}
        />
      </Link>
    </div>
  )
}

export default Brand
