import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"

import Brand from "../Brand/brand"
import Navigation from "../Navigation/navigation"

import css from "./header.module.scss"

const Header = ({ siteTitle }) => {
  const [isSticky, setSticky] = useState(false)
  const ref = useRef(null)
  const handleScroll = () => {
    if (ref.current) {
      setSticky(window.scrollY > 200)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  return (
    <header className={`${css.header} ${isSticky ? css.sticky : ""}`} ref={ref}>
      <div className={`container ${css.header__container}`}>
        <Brand innerColor={isSticky ? "#000000" : "#ffffff"} />
        <Navigation />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
