import React from "react"
import PropTypes from "prop-types"

const BrandName = ({ color, imageWidth }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 64"
      width={imageWidth}
    >
      <path
        className="st0" fill={color} 
        d="M43.2,39.2c-0.5-1.5-1.2-2.9-2.1-4.2c-0.9-1.3-2-2.4-3.3-3.3c-1.2-0.9-2.6-1.6-4-2.1c2.2-1.1,3.9-2.7,5.2-4.7
        c1.3-2.1,1.9-4.4,1.9-6.9c0-1.8-0.4-3.5-1.1-5.1c-0.7-1.6-1.7-3-2.8-4.2c-1.2-1.2-2.6-2.2-4.2-2.8c-1.6-0.7-3.3-1-5.2-1H7.2
        C6.4,4.8,6,5.2,6,6V58c0,0.3,0.1,0.6,0.4,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.4-0.5,0.4-0.8V7.2h4.2v50.5
        c0,0.3,0.1,0.7,0.3,0.9c0.2,0.3,0.5,0.5,0.9,0.5h15c2.1,0,4.1-0.4,5.9-1.2c1.8-0.8,3.4-1.9,4.8-3.2c1.4-1.4,2.4-3,3.2-4.8
        C43.6,48,44,46,44,43.9C44,42.3,43.7,40.7,43.2,39.2z M33.8,32.2c1.6,0.7,2.9,1.6,4.1,2.7c1.2,1.2,2.1,2.5,2.7,4.1
        c0.7,1.6,1,3.2,1,5c0,1.8-0.3,3.5-1,5c-0.7,1.5-1.6,2.9-2.7,4.1c-1.2,1.2-2.5,2.1-4.1,2.7c-1.5,0.7-3.2,1-5,1H14.9V31.1h13.6
        c0.1,0,0.2,0.1,0.4,0.1C30.6,31.2,32.2,31.5,33.8,32.2z M14.9,28.8V7.2h12.7c1.5,0,2.9,0.3,4.2,0.9c1.3,0.6,2.5,1.4,3.4,2.3
        c1,1,1.8,2.1,2.3,3.4c0.6,1.3,0.9,2.7,0.9,4.2c0,1.5-0.3,2.8-0.9,4.1c-0.6,1.3-1.4,2.5-2.3,3.4c-1,1-2.1,1.8-3.5,2.3
        c-1.3,0.6-2.7,0.9-4.2,0.9H14.9z"
      />
      <path
        className="st0" fill={color}
        d="M89.6,56.8H65.3V6c0-0.8-0.4-1.2-1.2-1.2c-0.3,0-0.6,0.1-0.8,0.4C63.1,5.5,63,5.7,63,6v50.8h-4.2V6.3
        c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.4,0.5-0.4,0.8V58c0,0.7,0.4,1.1,1.1,1.1h32
        c0.8,0,1.2-0.4,1.2-1.2C90.8,57.2,90.4,56.8,89.6,56.8z"
      />
      <path
        className="st0" fill={color}
        d="M133.6,56.6h-32.1V36.9h25.2c0.3,0,0.6-0.1,0.8-0.4c0.3-0.2,0.4-0.5,0.4-0.8c0-0.8-0.4-1.2-1.2-1.2h-25.2
        l0.1-4.4h24.8c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.4-0.8-0.4h-25V7.2h32.1
        c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.4-0.8-0.4h-33.2c-0.3,0-0.6,0.1-0.8,0.3
        c-0.3,0.2-0.4,0.5-0.4,0.8v51.9c0,0.3,0.1,0.6,0.4,0.9c0.2,0.2,0.5,0.4,0.9,0.4h33.2c0.4,0,0.6-0.1,0.8-0.4
        c0.2-0.3,0.3-0.6,0.3-0.8c0-0.3-0.1-0.6-0.3-0.9C134.2,56.8,133.9,56.6,133.6,56.6z"
      />
      <g>
        <path
          className="st0" fill={color}
          d="M169.5,51.6c-1.3-1.8-2.7-3.9-4.4-6.2c-1.8-2.5-3.5-4.9-5.1-7.1c-1.7-2.3-3.4-4.7-5.2-7.3
          c-1.6-2.3-3.2-4.5-4.7-6.6c-1.4-2-2.7-3.7-3.7-5c-0.9-1.3-1.6-2.3-2-2.7c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.7,0.4
          c-0.2,0.2-0.4,0.5-0.4,0.8V58c0,0.8,0.4,1.2,1.2,1.2c0.3,0,0.6-0.1,0.8-0.4c0.2-0.3,0.4-0.5,0.4-0.8V21l26.9,37.7
          c0.2,0.3,0.5,0.5,0.9,0.5c0.3,0,0.6-0.1,0.8-0.3c0.3-0.2,0.4-0.5,0.4-0.8c0-0.1,0-0.2-1.2-2C171.7,54.8,170.7,53.3,169.5,51.6z"
        />
        <path
          className="st0" fill={color}
          d="M181.9,6c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8
          v48.4L144.5,5.6c-0.3-0.3-0.6-0.5-0.9-0.5c-0.5,0-1.1,0.2-1.1,1.2c0,0.4,0.1,0.6,0.2,0.7l37.2,51.7c0.2,0.3,0.5,0.5,0.9,0.5
          c0.8,0,1.2-0.4,1.2-1.2L181.9,6z"
        />
      </g>
      <path
        className="st0" fill={color}
        d="M238.2,21.4c-1.4-3.3-3.4-6.2-5.8-8.6c-2.4-2.4-5.3-4.4-8.6-5.8c-3.3-1.4-6.8-2.1-10.5-2.1h-15.6
        c-0.3,0-0.6,0.1-0.9,0.4c-0.2,0.2-0.3,0.5-0.3,0.9V58c0,0.3,0.1,0.6,0.3,0.8c0.2,0.3,0.5,0.4,0.8,0.4c0.8,0,1.2-0.4,1.2-1.2V7.2
        h4.4v50.6c0,0.3,0.1,0.6,0.3,0.9c0.2,0.3,0.5,0.4,0.9,0.4l8.9-0.1c3.7,0,7.2-0.8,10.5-2.2c3.3-1.4,6.2-3.3,8.6-5.8
        c2.4-2.4,4.4-5.3,5.8-8.6c1.4-3.3,2.1-6.8,2.1-10.6C240.4,28.2,239.7,24.7,238.2,21.4z M236.1,41.5c-1.3,3-3.1,5.6-5.3,7.8
        c-2.2,2.2-4.9,4-7.9,5.3c-3,1.3-6.2,2-9.6,2c-1.3,0-2.6,0.1-3.9,0.1h-3.8V7.2c1.3,0,2.6,0,3.8,0c1.4,0,2.7,0,3.9,0
        c3.4,0,6.6,0.7,9.7,2c3,1.3,5.7,3.1,7.9,5.3c2.2,2.2,4,4.9,5.3,7.9c1.3,3,1.9,6.3,1.9,9.7C238,35.3,237.4,38.5,236.1,41.5z"
      />
      <path
        className="st0" fill={color}
        d="M319.4,21.4c-1.4-3.3-3.4-6.2-5.8-8.6c-2.4-2.4-5.3-4.4-8.6-5.8c-3.3-1.4-6.8-2.1-10.5-2.1h-15.6
        c-0.3,0-0.6,0.1-0.9,0.4c-0.2,0.2-0.3,0.5-0.3,0.9V58c0,0.3,0.1,0.6,0.3,0.8c0.2,0.3,0.5,0.4,0.8,0.4c0.8,0,1.2-0.4,1.2-1.2V7.2
        h4.4v50.6c0,0.3,0.1,0.6,0.3,0.9c0.2,0.3,0.5,0.4,0.9,0.4l8.9-0.1c3.7,0,7.2-0.8,10.5-2.2c3.3-1.4,6.2-3.3,8.6-5.8
        c2.4-2.4,4.4-5.3,5.8-8.6c1.4-3.3,2.1-6.8,2.1-10.6C321.6,28.2,320.8,24.7,319.4,21.4z M319.2,31.9c0,3.4-0.7,6.6-1.9,9.6
        c-1.3,3-3.1,5.6-5.3,7.8c-2.2,2.2-4.9,4-7.9,5.3c-3,1.3-6.2,2-9.6,2c-1.3,0-2.6,0.1-3.9,0.1h-3.8V7.2c1.3,0,2.6,0,3.8,0
        c1.4,0,2.7,0,3.9,0c3.4,0,6.6,0.7,9.7,2c3,1.3,5.7,3.1,7.9,5.3c2.2,2.2,4,4.9,5.3,7.9C318.6,25.3,319.2,28.5,319.2,31.9z"
      />
      <path
        className="st0" fill={color}
        d="M335.6,5.1c-0.5-0.4-1.2-0.4-1.6,0c-0.2,0.2-0.3,0.5-0.3,0.8V58c0,0.3,0.1,0.6,0.3,0.8
        c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.3-0.2,0.4-0.5,0.4-0.8V6C336,5.6,335.8,5.3,335.6,5.1z"
      />
      <path
        className="st0" fill={color}
        d="M389.8,5.2c-0.2-0.2-0.5-0.3-0.8-0.3c-0.6,0-1,0.3-1.1,0.8l-19.5,49.1l-2-5.4c0.2-0.4,0.6-1.3,1.1-2.7
        c0.6-1.4,1.3-3.2,2.1-5.3c0.8-2.1,1.7-4.4,2.7-6.9c1.1-2.8,2.1-5.3,3-7.7c1-2.6,2-5.1,3-7.6l2.5-6.6c0.7-1.9,1.3-3.5,1.8-4.6
        c0.7-1.8,0.7-1.9,0.7-1.9c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.6,0-1,0.3-1.1,0.8l-16,40.5L349.5,5.6
        c-0.1-0.5-0.5-0.8-1.1-0.8c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.9c0,0,0,0.1,0.8,2.3c0.5,1.4,1.3,3.3,2.1,5.6l0.2,0.4
        c0.8,2.2,1.8,4.7,2.8,7.5c1,2.7,2.2,5.7,3.5,9.2l1.6,4.2c0.7,1.7,1.3,3.4,2,5.1c1.2,3,2.2,5.7,3.2,8.4c1,2.5,1.8,4.7,2.5,6.4
        c0.7,1.8,1.1,2.9,1.3,3.3c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.2,0.5,0.3l0,0h0c0.5,0,0.9-0.3,1.1-0.8c0.2-0.5,0.6-1.5,1.3-3.3
        c0.7-1.7,1.5-3.8,2.5-6.4c1.2-3,2.3-5.7,3.3-8.4c1-2.5,2.3-5.8,3.7-9.3c1.2-2.9,2.4-6,3.6-9.1c1.1-2.9,2.2-5.5,3.1-7.9
        c0.9-2.3,1.6-4.2,2.2-5.6c0.9-2.2,0.9-2.3,0.9-2.3C390.2,5.7,390.1,5.4,389.8,5.2z"
      />
      <path
        className="st0" fill={color}
        d="M403.5,5.1c-0.5-0.4-1.2-0.4-1.6,0c-0.2,0.2-0.3,0.5-0.3,0.8V58c0,0.3,0.1,0.6,0.3,0.8
        c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.3-0.2,0.4-0.5,0.4-0.8V6C403.8,5.6,403.7,5.3,403.5,5.1z"
      />
      <g>
        <path
          className="st0" fill={color}
          d="M443.9,51.6c-1.3-1.8-2.7-3.9-4.4-6.2c-1.8-2.5-3.4-4.8-5.1-7.1c-1.7-2.3-3.4-4.8-5.2-7.3
          c-1.6-2.3-3.2-4.5-4.7-6.6c-1.4-2-2.7-3.7-3.7-5c-1.2-1.7-1.8-2.5-2-2.7c-0.3-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.5,0.1-0.7,0.4
          c-0.2,0.2-0.4,0.5-0.4,0.8V58c0,0.8,0.4,1.2,1.2,1.2c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.4-0.5,0.4-0.8V21l26.9,37.7
          c0.2,0.3,0.5,0.5,0.9,0.5c0.3,0,0.6-0.1,0.8-0.3c0.3-0.2,0.4-0.5,0.4-0.8c0-0.1,0-0.2-1.2-2C446.2,54.8,445.2,53.3,443.9,51.6z"
        />
        <path
          className="st0" fill={color}
          d="M456.4,6c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8
          v48.4L419,5.6c-0.3-0.3-0.6-0.5-0.9-0.5c-0.5,0-1.1,0.2-1.1,1.2c0,0.4,0.1,0.6,0.2,0.7l37.2,51.7c0.2,0.3,0.5,0.5,0.9,0.5
          c0.8,0,1.2-0.4,1.2-1.2L456.4,6z"
        />
      </g>
      <path
        className="st0" fill={color}
        d="M504.9,56.6h-32.1V36.9H498c0.3,0,0.6-0.1,0.8-0.4c0.3-0.2,0.4-0.5,0.4-0.8c0-0.8-0.4-1.2-1.2-1.2h-25.2
        l0.1-4.4h24.8c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.4-0.8-0.4h-25V7.2h32.1
        c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.4-0.8-0.4h-33.2c-0.3,0-0.6,0.1-0.8,0.3
        c-0.3,0.2-0.4,0.5-0.4,0.8v51.9c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4h33.2c0.4,0,0.6-0.1,0.8-0.4
        c0.2-0.3,0.3-0.6,0.3-0.8c0-0.3-0.1-0.6-0.3-0.9C505.4,56.8,505.2,56.6,504.9,56.6z"
      />
      <rect x="246.4" y="31.2" className="st0" fill={color} width="24.7" height="2.2" />
    </svg>
  )
}

BrandName.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number
}

BrandName.defaultProps = {
  color: "#000000",
  imageWidth: 120,
}

export default BrandName
