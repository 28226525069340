import React from "react"
import PropTypes from "prop-types"

const Logo = ({LineColor1, LineColor2, LineColorOuter, size}) => {
  return (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 512 512">
    <path
      className="path_01" fill={LineColor1}
      d="M195.8,366.4c-0.3,0-0.5-0.1-0.7-0.3L77,247.9c-0.4-0.4-0.4-1.1,0-1.5L201.4,122c0.2-0.2,0.5-0.3,0.7-0.3
	c0.3,0,0.5,0.1,0.7,0.3l115,115c0.5,1.8,1.2,3,2.3,3.7c0.3,0.2,0.4,0.5,0.5,0.8c0,0.3-0.1,0.6-0.3,0.8l-2.5,2.5
	c-0.2,0.2-0.5,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3L202.2,130.6L85.5,247.2l114.2,114.2c0.4,0.4,0.4,1.1,0,1.5l-3.2,3.2
	C196.4,366.2,196.1,366.4,195.8,366.4L195.8,366.4z"
    />
    <path
      className="path_02" fill={LineColorOuter}
      d="M256,498L14,256L256,14l242,242L256,498L256,498z M256,1L1,256l255,255l255-255L256,1L256,1z"
    />
    <path
      className="path_03" fill={LineColor2}
      d="M307.4,366.4c-0.3,0-0.5-0.1-0.7-0.3l-115-115c-0.5-1.8-1.2-3-2.3-3.7c-0.3-0.2-0.4-0.5-0.5-0.8
	c0-0.3,0.1-0.6,0.3-0.8l2.5-2.5c0.2-0.2,0.5-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3l114.2,114.2L424,240.9L309.8,126.7
	c-0.4-0.4-0.4-1.1,0-1.5l3.2-3.2c0.2-0.2,0.5-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3l118.1,118.1c0.4,0.4,0.4,1.1,0,1.5L308.1,366.1
	C307.9,366.2,307.7,366.4,307.4,366.4L307.4,366.4z"
    />
  </svg>
)}

Logo.propTypes = {
  LineColor1: PropTypes.string,
  LineColor2: PropTypes.string,
  LineColorOuter: PropTypes.string,
  size: PropTypes.number
}


Logo.defaultProps = {
  LineColor1: "#000000",
  LineColor2: "#000000",
  LineColorOuter: "#000000",
  size: 120
}


export default Logo
