import React from "react"

import css from "./footer.module.scss"
import { Link } from "gatsby"

const Footer = () => (
  <footer className={css.footer}>
    <div className={css.footer__container}>
      <div className={css.footer__group}>
        <h4 className={css.footer__title}>Retail Products</h4>
        <ul className={css.footer__list}>
          <li className={css.footer__item}>
            <Link
              to="/services/retail-products/home-decor"
              className={css.footer__link}
            >
              Home Décor
            </Link>
          </li>
          <li className={css.footer__item}>
            <Link
              to="/services/retail-products/office-products"
              className={css.footer__link}
            >
              Office Products
            </Link>
          </li>
          <li className={css.footer__item}>
            <Link
              to="/services/retail-products/pet-supplies"
              className={css.footer__link}
            >
              Pet Supplies
            </Link>
          </li>
          <li className={css.footer__item}>
            <Link
              to="/services/retail-products/sport-and-outdoors"
              className={css.footer__link}
            >
              Sport &amp; Outdoors
            </Link>
          </li>
          <li className={css.footer__item}>
            <Link
              to="/services/retail-products/toys-and-games"
              className={css.footer__link}
            >
              Toys &amp; Games
            </Link>
          </li>
          <li className={css.footer__item}>
            <Link
              to="/services/retail-products/books"
              className={css.footer__link}
            >
              Books
            </Link>
          </li>
        </ul>
      </div>
      <div className={css.footer__group}>
        <h4 className={css.footer__title}>Tailored Services</h4>
        <ul className={css.footer__list}>
          <li className={css.footer__item}>
            <Link
              to="/services/tailored-services/product-development"
              className={css.footer__link}
            >
              Product Development
            </Link>
          </li>
          <li className={css.footer__item}>
            <Link
              to="/services/tailored-services/online-launchpad"
              className={css.footer__link}
            >
              Online Launchpad
            </Link>
          </li>
          <li className={css.footer__item}>
            <Link
              to="/services/tailored-services/ordering-tools"
              className={css.footer__link}
            >
              Ordering Tools
            </Link>
          </li>
          <li className={css.footer__item}>
            <Link
              to="/services/tailored-services/fulfillment"
              className={css.footer__link}
            >
              Fulfillment (US &amp; UK)
            </Link>
          </li>
        </ul>
      </div>
      <div className={css.footer__group}>
        <h4 className={css.footer__title}>Custom Services</h4>
        <ul className={css.footer__list}>
          <li className={css.footer__item}>
            <Link
              to="/services/custom-services/e-textbooks"
              className={css.footer__link}
            >
              E-Textbooks
            </Link>
          </li>
          <li className={css.footer__item}>
            <Link
              to="/services/custom-services/print-on-demand"
              className={css.footer__link}
            >
              Print on Demand
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
)

export default Footer
