import React from "react"
import { Link } from "gatsby"

import css from "./legal.module.scss"

const Legal = () => (
  <div className={css.legal}>
    <div className={css.legal__container}>
      <div className={css.legal__item}>
        <p className={css.legal__copyright}>
          &copy; {new Date().getFullYear()}
          {` `}
          <Link to="/" className={css.legal__link}>
            Blend Divine
          </Link>
          {` `}
          All Rights Reserved
        </p>
      </div>
      {/* <div className={css.legal__item}>
        <Link to="/privacy-policy" className={css.legal__link}>
          Privacy Policy
        </Link>
      </div>
      <div className={css.legal__item}>
        <Link to="/terms-of-use" className={css.legal__link}>
          Terms of Use
        </Link>
      </div> */}
    </div>
  </div>
)

export default Legal
