import React, { Fragment, useState } from "react"
import { Link } from "gatsby"
import css from "./navigation.module.scss"
import MegaMenu from "../MegaMenu/mega-menu"
import LinkGroup from "../LinkGroup/link-group"

const Navigation = isSticky => {


  const [navOpen, setNavOpen] = useState(false)

  return (
    <>
      <div className={css.nav__toggle} onClick={() => setNavOpen(!navOpen)}>
        <button className={css.nav__toggleBtn}>
          <svg
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 384.97 384.97"
            width="24"
          >
            <path
              d="M12.03,120.303h360.909c6.641,0,12.03-5.39,12.03-12.03c0-6.641-5.39-12.03-12.03-12.03H12.03
			c-6.641,0-12.03,5.39-12.03,12.03C0,114.913,5.39,120.303,12.03,120.303z"
            />
            <path
              d="M372.939,180.455H12.03c-6.641,0-12.03,5.39-12.03,12.03s5.39,12.03,12.03,12.03h360.909c6.641,0,12.03-5.39,12.03-12.03
			S379.58,180.455,372.939,180.455z"
            />
            <path
              d="M372.939,264.667H132.333c-6.641,0-12.03,5.39-12.03,12.03c0,6.641,5.39,12.03,12.03,12.03h240.606
			c6.641,0,12.03-5.39,12.03-12.03C384.97,270.056,379.58,264.667,372.939,264.667z"
            />
          </svg>
        </button>
      </div>
      <nav className={`${css.nav} ${navOpen ? css.navOpen : ""}`}>
        <ul className={css.nav__list}>
          <li className={css.nav__item}>
            <Link className={css.nav__link} activeClassName={css.active} to="/">
              Home
            </Link>
          </li>
          <li className={`${css.nav__item} ${css.nav__dropbtn}`}>
            <div className={css.nav__link} >
              Products &amp; Services
            </div>
            <div className={css.nav__megadropdown}>
              <MegaMenu>
                <LinkGroup
                  linkData={{
                    title: "Retail Products",
                    titleUrl: "/services/retail-products",
                    links: [
                      {
                        page: "Home Décor",
                        pageUrl: "home-decor",
                      },
                      {
                        page: "Office Products",
                        pageUrl: "office-products",
                      },
                      {
                        page: "Pet Supplies",
                        pageUrl: "pet-supplies",
                      },
                      {
                        page: "Sport & Outdoors",
                        pageUrl: "sport-and-outdoors",
                      },
                      {
                        page: "Toys & Games",
                        pageUrl: "toys-and-games",
                      },
                      {
                        page: "Books",
                        pageUrl: "books",
                      },
                    ],
                  }}
                />

                <LinkGroup
                  linkData={{
                    title: "Tailored Services",
                    titleUrl: "/services/tailored-services",
                    links: [
                      {
                        page: "Product Development",
                        pageUrl: "product-development",
                      },
                      {
                        page: "Online Launchpad",
                        pageUrl: "online-launchpad",
                      },
                      {
                        page: "Ordering Tools",
                        pageUrl: "ordering-tools",
                      },
                      {
                        page: "Fulfillment (US & UK)",
                        pageUrl: "fulfillment",
                      },
                    ],
                  }}
                />

                <LinkGroup
                  linkData={{
                    title: "Custom Services ",
                    titleUrl: "/services/custom-services",
                    links: [
                      {
                        page: "E-Textbooks",
                        pageUrl: "e-textbooks",
                      },
                      {
                        page: "Print on Demand",
                        pageUrl: "print-on-demand",
                      },
                    ],
                  }}
                />
              </MegaMenu>
            </div>
          </li>
          <li className={css.nav__item}>
            <Link
              className={css.nav__link}
              activeClassName={css.active}
              to="/about-us"
            >
              About
            </Link>
          </li>
          <li className={css.nav__item}>
          <Link
            className={css.nav__link}
            activeClassName={css.active}
            to="/contact-us"
          >
            Contact Us
          </Link>
        </li>
        </ul>
      </nav>
    </>
  )
}

export default Navigation
