import React from "react"

import css from "./mega-menu.module.scss"
const MegaMenu = ({ children }) => (
  <div className={css.megamenu}>
    <div className={css.container}>{children}</div>
  </div>
)

export default MegaMenu
