import React from "react"
import { Link } from "gatsby"

import css from "./link-group.module.scss"

const LinkGroup = ({ linkData }) => {
  const { title, titleUrl, links } = linkData
  return (
    <div className={css.group}>
      {titleUrl && (
        <div className={css.title}>
          {title}
        </div>
      )}

      {!titleUrl && <div className={css.title}>{title}</div>}

      <ul className={css.list}>
        {links &&
          links.map((linkItem, index) => {
            const { page, pageUrl } = linkItem
            return (
              <li className={css.item} key={index}>
                <Link className={css.link} to={`${titleUrl}/${pageUrl}`}>
                  {page}
                </Link>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default LinkGroup
